<template>
	<v-card class="pa-6">
		<div class="text-h5 mb-8">{{ formTitle }}</div>
		<v-form v-model="form" @submit.prevent>
			<v-row class="flex-wrap">
				<v-col cols="12">
					<v-text-field
							v-if="(edited !== -1)"
							disabled
							readonly
							variant="outlined"
							placeholder="Our model"
							:model-value="info.department.name"
					></v-text-field>
					<v-autocomplete
							v-else
							v-model="fields.department"
							:items="departments"
							:loading="!departmentsLoaded"
							:disabled="!departmentsLoaded"
							variant="outlined"
							placeholder="Department"
							item-title="name"
							item-value="id"
							:rules="[rules.required]"
							:no-data-text="noDataText"
					></v-autocomplete>
				</v-col>
				<v-col cols="6">
					<div class="text-body-1 mb-2">Budget plan</div>
					<v-text-field
							v-model="fields.expected_plan"
							variant="outlined"
							placeholder="Budget plan"
							type="number"
							@update:model-value="checkSummary"
							:rules="[rules.required, rules.notNull]"
							prepend-inner-icon="mdi-currency-usd"
					></v-text-field>
				</v-col>
				<v-col cols="6">
					<div class="text-body-1 mb-2">Wage fund plan</div>
					<v-text-field
							v-model="fields.expected_wage_fund"
							variant="outlined"
							placeholder="Wage fund plan"
							type="number"
							:rules="[rules.required, rules.notNull]"
							@update:model-value="checkSummary"
							prepend-inner-icon="mdi-currency-usd"
							:error-messages="summaryError"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row justify="end" no-gutters class="mt-4 pa-0">
				<v-btn
						variant="text"
						@click="$emit('close')"
						class="mr-2"
				>Cancel</v-btn>
				<v-btn
						v-if="(edited !== -1)"
						:disabled="formLoading || summaryError.length > 0"
						:loading="formLoading"
						type="submit"
						color="primary"
						@click="editPlanDepartment"
				>Save</v-btn>
				<v-btn
						v-else
						:loading="formLoading"
						:disabled="formLoading || summaryError.length > 0"
						type="submit"
						color="primary"
						@click="addPlanDepartment"
				>Add</v-btn>
			</v-row>
		</v-form>
	</v-card>
</template>

<script>
import apiBudgetFinance from '@/api/budget-finance';

export default {
	name: 'BudgetFinanceDepartmentEditDialog',

	components: {

	},


	props: {
		rawDepartments: {
			type: Array
		},
		departmentsLoaded: {
			type: Boolean
		},
		edited: {},
		info: {},
		period: {},
		selected: {}
	},


	data() {
		return {
			rules: {
				required: value => {
					if (value === [] || value === '') return 'Required field';
					if (value) return true;
					return 'Required field'
				},
				notNull: value => {
					if (value === 0 || value === '0') return 'Should not be equal to null';
					if (value) return true;
				},
			},
			form: false,
			formLoading: false,
			fields: {
				department: null,
				expected_plan: null,
				expected_wage_fund: null,
			},
			summaryError: '',
		}
	},


	computed: {
		formTitle () {
			return this.edited === -1 ? 'Add department' : 'Edit Department'
		},
		departments() {
			let departments = structuredClone(this.rawDepartments);
			this.selected.forEach(target => {
				departments.forEach((department, index) => {
					if (target === department.name) {
						departments.splice(index, 1)
					}
				})
			})
			return departments
		},
		noDataText() {
			if (this.rawDepartments.length === 0) {
				return 'Departments list is empty'
			} else if (this.departments.length === 0) {
				return 'All departments added'
			} else {
				return ''
			}
		},
	},


	created() {
		if (this.edited !== -1) {
			this.fields.department = this.info.department.id;
			this.fields.expected_plan = this.info.expected_plan;
			this.fields.expected_wage_fund = this.info.expected_wage_fund;
		}
	},


	watch: {

	},


	methods: {

		checkSummary() {
			if (Number(this.fields.expected_wage_fund) > Number(this.fields.expected_plan)) {
				this.summaryError = 'The "Wage fund plan" value cannot exceed the "Budget plan" value'
			} else {
				this.summaryError = ''
			}
		},

		async addPlanDepartment() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const response = await apiBudgetFinance.addPlanDepartment({
					expected_plan: this.fields.expected_plan,
					expected_wage_fund: this.fields.expected_wage_fund,
					department: this.fields.department,
					period: this.period
				});
				this.$emit('add', response)
				this.$emit('close')
			} catch (error) {
				console.log('add department error: ', error)
			} finally {
				this.formLoading = false;
			}
		},

		async editPlanDepartment() {
			if (!this.form) return
			try {
				this.formLoading = true;
				const response = await apiBudgetFinance.editPlanDepartment(this.info.id, {
					expected_plan: this.fields.expected_plan,
					expected_wage_fund: this.fields.expected_wage_fund,
				});
				this.$emit('edit', response)
				this.$emit('close')
			} catch (error) {
				console.log('update department error: ', error);
			} finally {
				this.formLoading = false;
			}
		},

	}
}
</script>

<style scoped>

</style>